<template>
  <div
    :id="lead.id"
    class="h-card-wrapper"
  >
    <div class="left-section">
      <div class="logo-row">
        <img
          class="logo"
          :src="imageUrl"
          alt="lead-logo-img"
          @error="imageError"
        >
        <div>
          <p
            class="lead-name"
            :title="lead.storeName"
          >
            {{ lead.storeName || 'N/A' }}
          </p>
          <a
            class="lead-url"
            :href="lead.websiteUrl"
            target="_blank"
          >
            {{ lead.domain || 'N/A' }}
          </a>
          <p class="lead-location">
            <feather-icon
              icon="MapPinIcon"
              size="12"
            />
            {{ lead.country || 'N/A' }}
          </p>
          <p class="lead-info">
            <span class="bold-text">Year Founded</span>: {{ lead.yearFounded || 'N/A' }}
          </p>
          <p class="lead-info">
            <span class="bold-text">Average Retail Price, $</span>: {{ avgRetailPrice }}
          </p>
        </div>
      </div>
      <p class="lead-info">
        <span class="bold-text">Industry Category</span>: {{ lead.category || 'N/A' }}
      </p>
      <div class="contact-details">
        <p class="lead-info">
          <span class="bold-text">Company Email</span>: <a
            v-if="lead.email"
            :href="`mailto:${lead.email}`"
            class="contact-text"
            @click="trackCompanyContact('email', lead.email)"
          >{{ lead.email }}</a>
          <span v-else>N/A</span>
        </p>
        <p class="lead-info">
          <span class="bold-text">Company Phone Number</span>: <a
            v-if="lead.phoneNumber"
            :href="`tel:${lead.phoneNumber}`"
            class="contact-text"
            @click="trackCompanyContact('phone number', lead.phoneNumber)"
          >{{ lead.phoneNumber }}</a>
          <span v-else>N/A</span>
        </p>
        <p class="lead-info">
          <span class="bold-text">Company Address</span>: {{ leadAddress }}
        </p>
        <div class="social-media">
          <b-link
            v-show="lead.linkedinUrl"
            target="_blank"
            :href="lead.linkedinUrl"
            @click="trackCompanyContact('social link (LinkedIn)', lead.linkedinUrl)"
          >
            <linked-in-icon />
          </b-link>
          <b-link
            v-show="lead.facebookUrl"
            target="_blank"
            :href="lead.facebookUrl"
            @click="trackCompanyContact('social link (Facebook)', lead.facebookUrl)"
          >
            <facebook-icon />
          </b-link>
          <b-link
            v-show="lead.instagramUrl"
            target="_blank"
            :href="lead.instagramUrl"
            @click="trackCompanyContact('social link (Instagram)', lead.instagramUrl)"
          >
            <instagram-icon />
          </b-link>
          <b-link
            v-show="lead.tiktokUrl"
            target="_blank"
            :href="lead.tiktokUrl"
            @click="trackCompanyContact('social link (TikTok)', lead.tiktokUrl)"
          >
            <tik-tok-icon class="social-icon" />
          </b-link>
        </div>
      </div>
    </div>
    <div class="right-section">
      <expandable-text
        v-if="lead.aboutUs"
        title="Company description"
        :description="lead.aboutUs"
      />
      <expandable-text
        v-if="lead.partnershipInsights"
        title="Partnership insights"
        :description="lead.partnershipInsights"
      />
      <expandable-text
        v-if="lead.topBrands.length"
        title="Leads Brand Portfolio"
        :description="lead.topBrands.join(',')"
      />

      <contacts-table
        v-if="showContacts"
        :lead-data="lead"
      />
      <div
        v-else
        class="save-btn-wrapper"
      >
        <b-button
          v-if="!isSaved"
          variant="light"
          :class="{'save-btn': true, 'reduce-padding': !isSavingLead}"
          :disabled="isSavingLead"
          @click="handleSaveLead(false)"
        >
          <b-spinner
            v-if="isSavingLead"
            class="mr-1"
            small
            label="Loading..."
          />
          <span v-if="isSavingLead">Saving...</span>
          <span v-else>Save lead & Get contacts
            <span class="credit-block">{{ creditRates.save }} <coin-icon /></span>
          </span>
        </b-button>
        <b-button
          v-else
          class="save-btn view-saved-lead-btn"
          @click="handleViewLead"
        >
          View saved lead
        </b-button>
      </div>
    </div>
    <no-contacts-modal
      v-if="hasNoContacts"
      :lead-name="lead.storeName"
      :lead-domain="lead.domain"
      @on-save-lead-click="handleSaveLead(true)"
    />
  </div>
</template>
<script>
import { BLink, BButton, BSpinner } from 'bootstrap-vue'
import InstagramIcon from '@/@core/assets/svg-components/InstagramIcon.vue'
import FacebookIcon from '@/@core/assets/svg-components/FacebookIcon.vue'
import LinkedInIcon from '@/@core/assets/svg-components/LinkedInIcon.vue'
import TikTokIcon from '@/@core/assets/svg-components/TikTokIcon.vue'
import { enrichTrackName, getDefaultProductImage } from '@/@core/utils/utils'
import store from '@/store'
import {
 FETCH_AVAILABLE_CREDITS, FETCH_LEAD_POSITION, GET_ONE_LEAD, SET_AVAILABLE_CREDITS, UPDATE_SAVED_LEAD,
} from '@/store/modules/leadai.module'
import CoinIcon from '@/@core/assets/svg-components/CoinIcon.vue'
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import ExpandableText from './ExpandableText.vue'
import ContactsTable from './ContactsTable.vue'
import allCountryData from '../../../../assets/data/countryCode.json'
import { tabData } from '../tabData'
import NoContactsModal from './NoContactsModal.vue'

export default {
  name: 'LeadsAiHorizontalCard',
  components: {
    InstagramIcon,
    FacebookIcon,
    LinkedInIcon,
    TikTokIcon,
    BLink,
    BButton,
    ExpandableText,
    ContactsTable,
    BSpinner,
    CoinIcon,
    NoContactsModal,
  },
  props: {
    lead: {
      type: Object,
      default: () => ({
        storeName: '',
        category: '',
        country: '',
        locality: '',
        storeTypes: [],
        averageRetailPrice: [],
      }),
      required: true,
    },
    showContacts: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageUrl: '',
      leadId: '',
      isSavingLead: false,
      hasNoContacts: false,
      isSaved: false,
      getDefaultProductImage,
      allCountryData,
    }
  },
  computed: {
    creditRates() {
      return store.state.leadai.currentCreditRates
    },
    avgRetailPrice() {
      if (!this.lead.averageRetailPrice || this.lead.averageRetailPrice.length === 0) {
        return 'N/A'
      }
      const updatedPrices = this.lead.averageRetailPrice.map(price => price.replaceAll(/\$/g, ''))
      return updatedPrices.join(', ')
    },
    leadAddress() {
      if (!this.lead?.address) return 'N/A'

      const {
        streetAddress, locality, region, state, country,
      } = this.lead.address
      const addressParts = [streetAddress, locality, region || state, country].filter(Boolean)

      return addressParts.length ? addressParts.join(', ') : 'N/A'
    },
    saveLeadPayload() {
      return {
        domain: this.lead?.domain,
        storeName: this.lead?.storeName,
        additionalData: {
          partnershipInsights: this.lead?.partnershipInsights,
          avgRetailPrice: this.lead?.averageRetailPrice,
          topBrands: this.lead?.topBrands,
        },
      }
    },
  },
  beforeMount() {
    this.imageUrl = getDefaultProductImage(300, 250)
    if (this.lead.smallLogoUrl) {
      const image = new Image()
      image.onload = () => {
        if (image.width > 75) {
          this.imageUrl = this.lead.smallLogoUrl
        }
      }
      image.src = this.lead.smallLogoUrl
    }
  },
  created() {
    if (this.lead?.externalBuyerId) {
      this.leadId = this.lead?.externalBuyerId
    }
    if (this.lead?.viewed) {
      this.isSaved = true
    }
  },
  methods: {
    imageError(e) {
      e.target.src = getDefaultProductImage(300, 250)
    },
    async fetchAvailableCredits() {
      const availableCreditsResponse = await store.dispatch(FETCH_AVAILABLE_CREDITS, {
        brandId: store.getters.currentEntityId,
      })

      if (availableCreditsResponse?.data) {
        store.commit(SET_AVAILABLE_CREDITS, availableCreditsResponse.data?.availableCredits)
      }
    },
    trackCompanyContact(contactName, data) {
      const activeTab = tabData.find(tab => tab.id === this.$route.query?.tab ?? 'leads-ai')
      analytics.track(enrichTrackName(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_COMPANY_CONTACT, {
        tabName: `${activeTab ? activeTab.name : 'Leads AI'} tab`,
        contactName,
      }), {
        companyName: this.lead?.storeName,
        data,
      })
    },
    handleSaveLeadSuccess(data) {
      this.fetchAvailableCredits()
      this.leadId = data?.id
      this.isSaved = true
      apiToastSuccess('Lead saved successfully!')
      store.commit(UPDATE_SAVED_LEAD, this.lead?.domain)
      analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_SAVE_LEAD_SUCCESS, {
        leadData: this.lead,
        response: data,
      })
    },
    async handleSaveLead(isForced = false) {
      if (isForced) {
        analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_NO_CONTACTS_CONFIRM_BTN, {
          leadData: this.lead,
        })
        this.$bvModal.hide(`no-contacts-modal-${this.lead.domain}`)
      } else {
        analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_SAVE_LEAD_BUTTON, {
          leadData: this.lead,
        })
      }
      try {
        this.isSavingLead = true
        const payload = {
          ...this.saveLeadPayload,
          force: isForced,
        }

        const res = await store.dispatch(GET_ONE_LEAD, payload)
        if (res?.data) {
          this.handleSaveLeadSuccess(res.data)
        }
      } catch (error) {
        this.handleFetchLeadDetailError(error)
        analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_SAVE_LEAD_FAILED, {
          leadData: this.lead,
          error: error ? error?.message ?? 'Something went wrong' : undefined,
        })
      } finally {
        this.isSavingLead = false
      }
    },
    async handleViewLead() {
      analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_VIEW_SAVED_LEAD_BUTTON, {
        leadData: this.lead,
      })
      if (this.leadId) {
        try {
          const res = await store.dispatch(FETCH_LEAD_POSITION, {
            id: this.leadId,
            payload: {
              pageNumber: 1,
              pageSize: 20,
            },
          })

          if (res?.data) {
            this.$router.replace({ name: 'leads-gen-ai', query: { tab: 'saved-leads', savedLeadId: this.leadId, pageNumber: res?.data?.pageNumber ?? 1 } })
          }
        } catch (err) {
          apiToastError(err)
        }
      }
    },
    handleFetchLeadDetailError(error) {
      if (error?.response?.status === 404) {
        this.hasNoContacts = true
        setTimeout(() => this.$bvModal.show(`no-contacts-modal-${this.lead.domain}`), 250)
      } else {
        apiToastError(error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/base/bootstrap-extended/_variables.scss';
.h-card-wrapper {
  padding: 24px;
  margin-top: 23px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #E0E0E0;
  border-radius: 6px;
  display: flex;

  p {
    margin-bottom: 0;
  }

  .left-section {
    width: 38%;
    padding-right: 1rem;

    .lead-info {
      span.bold-text {
        font-weight: 700;
      }

      margin-bottom: 6px;
    }

    .logo-row {
      display: flex;
      align-items: center;
      gap: 15px;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 15px;

      img.logo {
        width: 150px;
        height: 150px;
        border: 1px solid $lighter-gray;
      }

      .lead-name {
        font-weight: 700;
        line-height: normal;
        margin-top: 7px;
        margin-bottom: 3px;
      }

      .lead-url {
        font-size: 16px;
      }

      .lead-location {
        display: flex;
        align-items: center;
        gap: 5px;
        width: fit-content;
        font-size: 14px;
        margin-top: 15px;
        margin-bottom: 7px;
      }
    }

    .contact-details {
      margin-top: 12px;

      .contact-text {
        color: #8D82FD;
      }

      .lead-info {
        margin-bottom: 0;
      }

      .social-media {
        margin-top: 25px;
        svg {
          margin-right: 10px;
        }

        .social-icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .right-section {
    border-left: 1px solid $lighter-gray;
    padding-left: 18px;
    width: 62%;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    .save-btn-wrapper {
      margin-top: auto;
      display: flex;
      justify-content: flex-end;

      .save-btn {
        background-color: $custom-secondary !important;
        color: $text-color-v2 !important;
        border: none;
        font-weight: 400;
        min-width: 234px;

        .credit-block {
          display: inline-flex;
          color: #686767;
          font-size: 12px;
          line-height: 17px;
          border-radius: 5px;
          border: 1px solid #7163FF;
          padding: 3px 6px;
          align-items: center;
          gap: 4px;
          margin-left: 8px;
        }
      }

      .reduce-padding {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .view-saved-lead-btn {
        background-color: #8D82FD !important;
        color: #fff !important;
        border: none;
        font-weight: 400;
        width: 234px;
      }
    }
  }
}

@keyframes fadeOut {
  0% {
    border: 3px solid rgb(141, 130, 253);
    box-shadow: rgba(141, 130, 253, 0.2) 0px 8px 24px;
  }
  100% {
    border: 1px solid #E0E0E0;
    box-shadow: rgba(141, 130, 253, 0) 0px 8px 24px;
  }
}

.highlight {
  animation-name: fadeOut;
  animation-duration: 10s;
}

@media (max-width: 980px) {
  .h-card-wrapper {
    .left-section {
      .logo-row {
        flex-wrap: wrap;
      }
    }
  }
}

@media(max-width: 767px) {
  .h-card-wrapper {
    flex-direction: column;

    .left-section {
      width: unset;
      max-width: 100%;
    }

    .right-section {
      border-left: none;
      border-top:  1px solid $lighter-gray;
      width: unset;
      padding-left: 0;
      padding-top: 18px;
      margin-top: 18px;
      max-width: 100%;
    }
  }
}
</style>
