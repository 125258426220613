<template>
  <div>
    <div class="title-row">
      <p class="pink-title">
        Leads individual contacts*
      </p>
      <b-button
        v-if="contactsToDisplay.length"
        class="add-retailer-btn"
        @click="handleAddRetailer"
      >
        Add Retailer
      </b-button>
    </div>
    <p
      v-if="contactsToDisplay.length"
      class="title-condition"
    >
      *Click "Request phone" or "Request email" to fetch the latest contact info in real time. If it takes longer than expected, click the refresh icon next to "Contact Requested" to check the status. If validation fails within 3 minutes, you'll receive a LinkedIn profile link instead — without losing credits.
    </p>
    <div v-if="contactsToDisplay.length">
      <b-table
        responsive
        borderless
        thead-tr-class="contact-table-header"
        tbody-tr-class="contact-table-row"
        :items="contactsToDisplay"
        :fields="contactsFields"
      >
        <template #cell(phoneNumber)="data">
          <render-lead-contact
            contact-type="phone"
            :contact="data.item.phoneNumber || ''"
            :lead-id="leadData.id"
            :contact-id="data.item.id"
            :linkedin-profile="data.item.linkedinUrl"
            @reveal-contact-click="handleRevealContactEvents(data, 'phone', 'click')"
            @refresh-contact-click="trackRefreshContact(data, 'phone')"
            @reveal-contact-success="(response) => handleRevealContactEvents(data, 'phone', 'success', response)"
            @reveal-contact-failed="(response) => handleRevealContactEvents(data, 'phone', 'failed', response)"
          />
        </template>
        <template #cell(email)="data">
          <render-lead-contact
            contact-type="email"
            :contact="data.item.email || ''"
            :lead-id="leadData.id"
            :contact-id="data.item.id"
            :linkedin-profile="data.item.linkedinUrl"
            @reveal-contact-click="handleRevealContactEvents(data, 'email', 'click')"
            @refresh-contact-click="trackRefreshContact(data, 'email')"
            @reveal-contact-success="(response) => handleRevealContactEvents(data, 'email', 'success', response)"
            @reveal-contact-failed="(response) => handleRevealContactEvents(data, 'email', 'failed', response)"
          />
        </template>
      </b-table>
      <div
        v-if="contacts.length > 5"
        class="d-flex align-items-center justify-content-end expand-text"
      >
        <p
          v-if="isExpanded"
          @click="handleViewLess"
        >View less contacts
          <feather-icon
            icon="ChevronUpIcon"
            size="14"
          />
        </p>
        <p
          v-else
          @click="handleExpandToggle"
        >
          View more contacts
          <feather-icon
            icon="ChevronDownIcon"
            size="20"
          />
        </p>
      </div>
    </div>
    <div v-else>
      <p>This lead currently has no available and validated contacts. You were not charged for saving it.</p>
    </div>
    <b-modal
      :id="`no-email-modal-${leadData.id}`"
      dialog-class="no-email-modal-dialog"
      centered
      hide-header-close
      hide-footer
      @show="trackNoEmailModal('show')"
      @hide="trackNoEmailModal('hide')"
    >
      <div>
        <button
          class="close-btn"
          @click="$bvModal.hide(`no-email-modal-${leadData.id}`)"
        >
          <close-icon />
        </button>
        <p class="no-email-text text-center">
          Please reveal at least one lead’s email to add the lead as a retailer.
        </p>
      </div>
    </b-modal>
    <choose-email-modal
      :lead-id="leadData.id"
      :revealed-contacts="revealedEmailContacts"
      @on-choose-email="openAddRetailerModal"
    />
  </div>
</template>
<script>
import { BTable, BButton, BModal } from 'bootstrap-vue'
import parsePhoneNumberFromString from 'libphonenumber-js'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import CloseIcon from '@/@core/assets/svg-components/CloseIcon.vue'
import RenderLeadContact from './RenderLeadContact.vue'
import ChooseEmailModal from './ChooseEmailModal.vue'

export default {
  name: 'ContactsTable',
  components: {
    BTable,
    BButton,
    BModal,
    RenderLeadContact,
    ChooseEmailModal,
    CloseIcon,
  },
  props: {
    leadData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contacts: [],
      contactsFields: ['leadName', 'jobTitle', 'phoneNumber', 'email'],
      modalFormData: {},
      isExpanded: false,
      contactCodes: ['REVEAL_REQUIRED', 'EXTERNAL_REVEAL_REQUIRED', 'REVEAL_REQUESTED'],
    }
  },
  computed: {
    revealedEmailContacts() {
      return this.contacts.filter(contact => contact.email && !this.contactCodes.includes(contact.email))
    },
    contactsToDisplay() {
      return this.isExpanded ? this.contacts : this.contacts.slice(0, 5)
    },
  },
  created() {
    this.contacts = this.leadData?.contacts || []
  },
  methods: {
    handleExpandToggle() {
      this.isExpanded = true
      analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_VIEW_MORE_CONTACTS_BUTTON, {
        leadId: this.leadData?.id,
      })
    },
    handleViewLess() {
      this.isExpanded = false
      const leadCard = document.getElementById(this.leadData.id)
      if (leadCard) {
        const top = leadCard.getBoundingClientRect().top
        const offsetPosition = top + window.scrollY - 120
        window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
      }
    },
    trackNoEmailModal(action) {
      const trackName = action === 'show'
        ? constants.TRACKS.ACTIONS.LEAD_AI.BRAND_VIEWS_SAVED_LEADS_NO_EMAIL_WARNING_MODAL
        : constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLOSES_SAVED_LEADS_NO_EMAIL_WARNING_MODAL

      analytics.track(trackName, {
        companyName: this.leadData.storeName,
        id: this.leadData.id,
      })
    },
    handleAddRetailer() {
      analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_SAVED_LEADS_ADD_RETAILER_BTN, {
        companyName: this.leadData.storeName,
        id: this.leadData.id,
      })
      if (this.revealedEmailContacts.length === 0) {
        this.$bvModal.show(`no-email-modal-${this.leadData.id}`)
        return
      }

      if (this.revealedEmailContacts.length === 1) {
        this.openAddRetailerModal(this.revealedEmailContacts[0])
      } else {
        this.$bvModal.show(`choose-email-modal-${this.leadData.id}`)
      }
    },
    openAddRetailerModal(contact) {
      if (!contact) {
        return
      }
      this.$bvModal.hide(`choose-email-modal-${this.leadData.id}`)
      analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CHOOSES_SAVED_LEADS_RETAILER_EMAIL, {
        companyName: this.leadData?.storeName,
        id: this.leadData.id,
        email: contact.email,
      })
      const nameParts = contact.leadName === 'N/A' ? [] : contact.leadName.split(' ')
      const modalData = {
        id: this.leadData.id,
        storeName: this.leadData?.storeName ?? '',
        firstName: nameParts[0] ?? 'N/A',
        lastName: nameParts[1] ?? 'N/A',
        email: contact.email,
        phoneNumber: this.contactCodes.includes(contact.phoneNumber) ? '' : contact.phoneNumber,
        country: this.leadData.country,
        isFormReadonly: false,
      }
      let phoneObject
      if (contact.phoneNumber) {
        parsePhoneNumberFromString(contact.phoneNumber)
      }
      if (phoneObject) {
        modalData.phoneObject = phoneObject
      }

      this.modalFormData = {
        ...modalData,
      }
      this.$parent.$parent.$refs.addUserModal.openModal(modalData)
    },
    updateContactAfterRevealSuccess(data, type, response) {
      this.contacts = this.contacts.map(contact => {
        if (contact.id === data?.item?.id) {
          if (type === 'email') {
            return {
              ...contact,
              email: response,
            }
          } else {
            return {
              ...contact,
              phoneNumber: response,
            }
          }
        } else return contact
      })
    },
    trackRefreshContact(data, type) {
      analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_REFRESH_ICON, {
        contactId: data.item?.id,
        type,
      })
    },
    handleRevealContactEvents(data, type, event, response) {
      let trackName = constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_REVEAL_CONTACT_BUTTON

      if (event === 'success') {
        trackName = constants.TRACKS.ACTIONS.LEAD_AI.BRAND_REVEAL_CONTACT_SUCCESS
        this.updateContactAfterRevealSuccess(data, type, response)
      } else if (event === 'failed') {
        trackName = constants.TRACKS.ACTIONS.LEAD_AI.BRAND_REVEAL_CONTACT_FAILED
      }
      analytics.track(trackName, {
        companyName: this.leadData.storeName,
        id: this.leadData.id,
        leadName: data.item.leadName,
        jobTitle: data.item.jobTitle,
        contactId: data.item?.id,
        type,
        error: event === 'failed' && response ? response?.message ?? 'Something went wrong' : undefined,
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/base/bootstrap-extended/_variables.scss';
.title-row {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 12px;
}
.pink-title {
  color: #8D82FD;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
}
.title-condition {
  font-size: 11px;
  line-height: 17px;
  max-width: 670px;
}
.add-retailer-btn {
  background-color: $custom-secondary !important;
  color: $text-color-v2 !important;
  border: none;
  font-weight: 500;
  width: 141px;

  &:focus {
    background-color: $custom-secondary !important;
  }
}

.contact-table-header th {
  font-size: 12px !important;
  font-weight: 600;
  padding: 19px 14px;
}

.contact-table-row td {
  padding: 19px 14px;
  a {
    color: #240FFC;
  }

  .info-icon {
    margin-left: 3px;
    cursor: pointer;
  }
}

.profile-info-tooltip > .tooltip-inner {
  background-color: #7163FF;
  width: 74px;
  font-size: 10px;
  padding: 2px;
}

.profile-info-tooltip > .arrow::before {
  border-right-color: #7163FF;
  border-left-color: #7163FF;
}

.contact-table-row > td:nth-child(1),
.contact-table-row > td:nth-child(2) {
  width: 25%;
  max-width: 25%;
}

.contact-table-row > td:nth-child(3),
.contact-table-row > td:nth-child(4) {
  text-align: center;
  width: 25%;
  max-width: 25%;
  hyphens: auto;
}

button.reveal-contact-btn:disabled {
  cursor: not-allowed;
}

.reveal-contact-btn {
  background-color: $custom-secondary !important;
  color: $text-color-v2 !important;
  border: none;
  font-weight: 400;
  padding: 5px;
  width: 100%;
  min-height: 37px;
  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
  }

  &:focus {
    background-color: $custom-secondary !important;
  }

  .credit-block {
    display: inline-flex;
    color: #686767;
    font-size: 12px;
    line-height: 17px;
    border-radius: 5px;
    border: 1px solid #7163FF;
    padding: 3px 6px;
    align-items: center;
    gap: 4px;
  }
}

.reduce-padding {
  padding-top: 5px;
  padding-bottom: 5px;
}

.expand-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #686767;
  p {
    cursor: pointer;
  }
}

.no-email-modal-dialog {
    max-width: 360px;

    .modal-header {
      background: none;
      padding-top: 0;
      padding-bottom: 0;

      .close {
        color: #5E5873 !important
      }
    }

    .modal-body {
      display: flex;
      justify-content: center;
      padding: 15px 38px;
    }
  }

.no-email-text {
  font-size: 17px;
  color: #8D82FD;
  font-weight: 700;
  line-height: 24px;
  max-width: 280px;
  margin-bottom: 0;
}

</style>
