import { apiToastError } from '@/@core/utils/toast'
import { useRouter } from '@/@core/utils/utils'
import store from '@/store'
import { GET_SAVED_LEADS } from '@/store/modules/leadai.module'
import { ref } from '@vue/composition-api'

export const useSavedLeads = () => {
    const savedLeads = ref([])
    const totalSavedLeads = ref(0)
    const pageSize = 20
    const isFetchingData = ref(true)
    const { router, route } = useRouter()
    const currentPage = ref(route?.value.query?.pageNumber ? Number(route.value.query?.pageNumber) : 1)
    const leadToHighlight = ref(route?.value.query?.savedLeadId ?? '')

    const getSavedLeads = async () => {
        try {
            const response = await store.dispatch(GET_SAVED_LEADS, {
                pageNumber: currentPage.value,
                pageSize,
            })
            savedLeads.value = response.data.data
            totalSavedLeads.value = response.data.totalCount
        } catch (err) {
            apiToastError(err)
        } finally {
            isFetchingData.value = false
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            })
        }
    }

    const fetchLeadDetails = leadId => {
        router.push({ name: 'leads-gen-ai-detail', params: { leadId } })
    }

    const handlePageChange = async pageNumber => {
        currentPage.value = pageNumber
        await getSavedLeads()
    }

    return {
        savedLeads,
        getSavedLeads,
        totalSavedLeads,
        fetchLeadDetails,
        isFetchingData,
        currentPage,
        handlePageChange,
        pageSize,
        leadToHighlight,
    }
}
