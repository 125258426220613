<template>
  <div>
    <p class="tab-description">
      Keep a curated list of high-potential leads and <span>access key contact details of individuals </span> tied
      to each lead, <span>empowering you to reach out and drive valuable connections effortlessly.</span>
    </p>
    <div
      v-if="isFetchingData"
      class="spinner-container"
    >
      <b-spinner label="Loading..." />
    </div>
    <div v-if="!isFetchingData && viewedLeads.length > 0">
      <leads-ai-horizontal-card
        v-for="(lead, index) in viewedLeads"
        :key="lead.id + index"
        :lead="lead"
        :show-contacts="true"
      />
    </div>
    <div
      v-if="!isFetchingData && viewedLeads.length > 0"
      class="pagination-container"
    >
      <b-row>
        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalViewedLeads"
            :per-page="pageSize"
            page-class="hidden"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            ellipsis-class="custom-styles"
            @change="handlePageChange"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <div
      v-if="!isFetchingData && viewedLeads.length === 0"
      class="no-leads-container"
    >
      <p class="no-leads-text">
        No saved leads yet.
      </p>
      <small
        class="no-leads-subtext"
      >Check back soon once you save new leads.</small>
    </div>
    <AddUserModal
      ref="addUserModal"
      @on-add-retailer="(inputData) => trackAddRetailerEvents('click', inputData)"
      @on-add-retailer-close="(inputData) => trackAddRetailerEvents('closed', inputData)"
      @on-add-retailer-success="(inputData) => trackAddRetailerEvents('success', inputData)"
      @on-add-retailer-failed="(inputData, err) => trackAddRetailerEvents('failed', inputData, err)"
    />
  </div>
</template>
<script>
import { onBeforeUnmount, onMounted, watch } from '@vue/composition-api'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import {
 BSpinner, BPagination, BCol, BRow,
} from 'bootstrap-vue'
import AddUserModal from '@/views/apps/wallet/AddUserModal.vue'
import LeadsAiHorizontalCard from '../../LeadsAiHorizontalCard/LeadsAiHorizontalCard.vue'
import { useSavedLeads } from './useSavedLeads'

export default {
  name: 'SavedLeadsTab',
  components: {
    LeadsAiHorizontalCard,
    BSpinner,
    BPagination,
    BCol,
    BRow,
    AddUserModal,
  },
  methods: {
    trackAddRetailerEvents(event, inputData, err) {
      let trackName = constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_ADD_RETAILER_CONFIRM_BTN

      if (event === 'success') {
        trackName = constants.TRACKS.ACTIONS.LEAD_AI.BRAND_SAVED_LEADS_ADD_RETAILER_SUCCESS
      } else if (event === 'failed') {
        trackName = constants.TRACKS.ACTIONS.LEAD_AI.BRAND_SAVED_LEADS_ADD_RETAILER_FAILED
      } else if (event === 'closed') {
        trackName = constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLOSES_SAVED_LEADS_ADD_RETAILER_MODAL
      }

      analytics.track(trackName, {
        companyName: inputData?.entityName,
        id: inputData?.leadId,
        inputData,
        error: err ?? undefined,
      })
    },
  },
  setup() {
    const {
      savedLeads,
      currentPage,
      pageSize,
      totalSavedLeads,
      isFetchingData,
      getSavedLeads,
      handlePageChange,
      fetchLeadDetails,
      leadToHighlight,
    } = useSavedLeads()

    const startTime = Date.now()

    onMounted(() => {
      getSavedLeads()
    })

    watch(isFetchingData, val => {
      if (leadToHighlight?.value && !val) {
        setTimeout(() => {
          const leadEl = document.getElementById(leadToHighlight.value)

          if (leadEl) {
            const top = leadEl.getBoundingClientRect().top
            const offsetPosition = top + window.scrollY - 120
            window.scrollTo({ top: offsetPosition, behavior: 'smooth' })
            leadEl.classList.add('highlight')
          }
        }, 250)
      }
    })

    onBeforeUnmount(() => {
      analytics.track(constants.TRACKS.PAGES.BRAND_VIEWS_TAB_DATA.replace('<%tabName%>', 'Saved Leads tab'), {
        tabName: 'Saved Leads tab',
        timeSpent: `${(Date.now() - startTime) / 1000 / 60} minutes`,
      })
    })

    return {
      viewedLeads: savedLeads,
      totalViewedLeads: totalSavedLeads,
      fetchLeadDetails,
      isFetchingData,
      handlePageChange,
      currentPage,
      pageSize,
      leadToHighlight,
    }
  },
}
</script>
