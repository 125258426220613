<template>
  <div class="lead-ai-wrapper">
    <div
      v-if="isLoading"
      class="loader-wrapper"
    >
      <b-spinner
        label="Loading..."
      />
    </div>
    <div v-else>
      <b-tabs
        v-model="tabIndex"
        class="tablist"
        nav-wrapper-class="tablist-nav-wrapper"
        @input="handleTabChange"
      >
        <b-tab
          lazy
          title="LEADS AI"
        >
          <div class="w-100 h-100 flex align-center justify-center">
            <leads-ai-tab />
          </div>
        </b-tab>
        <b-tab
          lazy
          title="SAVED LEADS"
        >
          <div
            class="w-100 h-100 flex align-center justify-center"
          >
            <saved-leads-tab />
          </div>
        </b-tab>
        <b-tab
          lazy
          title="LEADS DATABASE"
        >
          <p class="tab-description">
            <span>"Leads Database"</span> allows you to explore our extensive collection of leads. Use filters and search
            options to navigate and discover potential matches tailored to your business needs.
          </p>
          <leads-database />
        </b-tab>
      </b-tabs>
      <div class="credits-section">
        <credit-icon :color="availableCredits >= 5 ? '#7163FF' : '#FB0404'" />
        <span :class="availableCredits >= 5 ? 'credit-text' : 'credit-text-red'">{{ availableCredits }} credits left</span>
        <span
          v-if="isEntityAdmin"
          class="get-more-text"
        >|</span>
        <span
          v-if="isEntityAdmin"
          class="get-more-text cursor-pointer"
          @click="handleGetCreditsClick"
        >Get credits</span>
      </div>
      <get-credits-modal
        :is-request-saved="isRequestSaved"
        @on-request-success="isRequestSaved = true"
      />
    </div>
  </div>
</template>

<script>
import { BTabs, BTab, BSpinner } from 'bootstrap-vue'
import CreditIcon from '@/@core/assets/svg-components/CreditIcon.vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import store from '@/store'
import {
 FETCH_AVAILABLE_CREDITS, FETCH_CURRENT_CREDIT_RATES, SET_AVAILABLE_CREDITS, SET_CURRENT_CREDIT_RATES,
} from '@/store/modules/leadai.module'
import { apiToastError } from '@/@core/utils/toast'
import LeadsDatabase from './tabs/LeadsDatabase.vue'
import LeadsAiTab from './tabs/LeadsAiTab/LeadsAiTab.vue'
import SavedLeadsTab from './tabs/SavedLeadsTab/SavedLeadsTab.vue'
import GetCreditsModal from './GetCreditsModal.vue'
import { tabData } from './tabData'

export default {
  components: {
    LeadsDatabase,
    SavedLeadsTab,
    LeadsAiTab,
    BTabs,
    BTab,
    CreditIcon,
    BSpinner,
    GetCreditsModal,
  },
  data() {
    return {
      isLoading: true,
      isRequestSaved: false,
      tabIndex: 0,
      tabData,
    }
  },
  computed: {
    isEntityAdmin() {
      return store.getters.isEntityAdmin
    },
    availableCredits() {
      return store.state.leadai.availableCredits
    },
    isAuthorized() {
      return store.getters.isLeadsAiRevampEnabled
    },
  },
  watch: {
    isAuthorized(val) {
      if (val === false) {
        this.$router.push({ name: 'misc-not-authorized' })
      }
    },
  },
  created() {
    const activeTab = this.$route.query?.tab
    this.updateActiveTab(activeTab)

    this.$watch(() => this.$route.query.tab, newTab => {
      this.updateActiveTab(newTab)
    })
    this.fetchCredits()
    this.$watch(() => store.getters.currentEntityId, val => {
      if (val) {
        this.fetchCredits()
      }
    })
  },
  methods: {
    updateActiveTab(activeTab) {
      this.tabIndex = this.tabData.findIndex(tab => tab.id === activeTab || (!activeTab && tab.default))
    },
    handleTabChange(tabIndex) {
      const activeTab = this.tabData[tabIndex]

      const query = activeTab.id === 'saved-leads' ? { ...this.$route.query } : {}
      query.tab = activeTab.id

      this.$router.replace({ name: 'leads-gen-ai', query })

      analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CHANGES_TAB, {
        tabName: activeTab.name,
      })
    },
    async fetchCredits() {
      if (!store.getters.currentEntityId) {
        return
      }
      try {
        const promises = [
          store.dispatch(FETCH_CURRENT_CREDIT_RATES),
          store.dispatch(FETCH_AVAILABLE_CREDITS, {
            brandId: store.getters.currentEntityId,
          }),
        ]
        const responses = await Promise.all(promises)
        this.handleFetchCreditSuccess(responses || [])
      } catch (errors) {
        let err1
        let err2
        if (Array.isArray(errors)) {
          [err1, err2] = errors
        }
        if (err1) {
          apiToastError(err1)
        }
        if (err2) {
          apiToastError(err2)
        }
      } finally {
        this.isLoading = false
      }
    },
    handleFetchCreditSuccess([creditRatesResponse, availableCreditsResponse] = []) {
      if (creditRatesResponse && availableCreditsResponse) {
        store.commit(SET_AVAILABLE_CREDITS, availableCreditsResponse.data?.availableCredits)
        store.commit(SET_CURRENT_CREDIT_RATES, creditRatesResponse.data?.actionRates)
      }
    },
    handleGetCreditsClick() {
      analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_GET_CREDITS_BUTTON, {
        availableCredits: this.availableCredits,
      })
      this.$bvModal.show('get-credits-modal')
    },
  },
}
</script>

<style lang="scss">
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;
}

.tab-description {
  font-size: 18px;
  line-height: 23px;
  max-width: 786px;
  margin-bottom: 2rem;

  span {
    font-weight: bold;
    color: #8d82fd
  }
}

.lead-ai-wrapper {
  position: relative;

  .credits-section {
    position: absolute;
    top: -8px;
    right: 0;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    gap: 8px;

    .credit-text {
      color: #292929;
    }

    .credit-text-red {
      color: #FB0404;
    }

    .get-more-text {
      color: #7163FF;
    }
  }
}
.tablist {
  position: relative;
  top: -20px;
  margin-top: 10px;

  .tablist-nav-wrapper {
    .nav-tabs {
      .nav-item {
        a {
          min-width: 167px;
          color: #686767;
          font-size: 16px;
          font-weight: 700;

          &.active {
            color: #292929;
            font-weight: bold;
          }

          &::after {
            background: #292929 !important;
          }
        }
      }
    }
  }
}

.close-btn {
  position: absolute;
  height: 35px;
  width: 35px;
  background-color: white;
  top: -15px;
  right: -15px;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all 0.23s ease 0.1s;
  &:hover {
    transform: translate(-3px, 3px);
  }
}

@media(max-width: 820px) {
  .tablist {
    .tablist-nav-wrapper {
      margin-bottom: 50px;
    }
  }

  .lead-ai-wrapper {
    .credits-section {
      right: unset;
      top: 34px;
    }
  }
}

@media(max-width: 576px) {
  .tablist {
    top: 0
  }
}
</style>
