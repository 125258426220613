export const tabData = [
  {
    id: 'leads-ai',
    name: 'Leads AI',
    default: true,
  },
  {
    id: 'saved-leads',
    name: 'Saved Leads',
  },
  {
    id: 'leads-database',
    name: 'Leads Database',
  },
]
